$primary: #c6c1bd;
$secondary:     #dee2e6 !default;
$light: #e9ecef !default;
$blue:    #007bff;
$enable-caret: true !default;
$enable-rounded: true !default;

$enable-shadows: true !default;
$enable-gradients: true !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-grid-classes: true !default;
$enable-print-styles: false !default;
$enable-validation-icons: true !default;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$line-height-base:  1.5 !default;
$nav-link-padding-y: .25rem !default;
$link-color: $blue !default;

@import "scss/functions";
@import "scss/mixins";
@import "scss/variables";
@import "scss/root";
@import "scss/reboot";
@import "scss/type";
@import "scss/images";
@import "scss/code";
@import "scss/grid";
@import "scss/tables";
@import "scss/forms";
@import "scss/buttons";
@import "scss/transitions";
@import "scss/dropdown";
@import "scss/button-group";
@import "scss/input-group";
@import "scss/custom-forms";
@import "scss/nav";
@import "scss/navbar";
@import "scss/card";
@import "scss/breadcrumb";
@import "scss/pagination";
@import "scss/badge";
@import "scss/jumbotron";
@import "scss/alert";
@import "scss/progress";
@import "scss/media";
@import "scss/list-group";
@import "scss/close";
@import "scss/toasts";
@import "scss/modal";
@import "scss/tooltip";
@import "scss/popover";
@import "scss/carousel";
@import "scss/spinners";
@import "scss/utilities";
